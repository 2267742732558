import React, { useState, useEffect } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import { useDispatch, useSelector } from "react-redux";
import {
  subscribeToCases,
  subscribeToMyCases,
} from "../../slices/api/casesApi";
import { setActiveCase } from "../../slices/CasesSlice";
import { useNavigate } from "react-router-dom";
import NamesOfAssignees from "./NamesOfAssignees";
import { formatStatus } from "../../utils";
import Constants from "../../constants/Constants";
import FilterListIcon from "@mui/icons-material/FilterList";
import { subscribeToDepartments } from "../../slices/api/departmentsApi";
import moment from "moment";
import NameResolver from "../common/NameResolver";
import { transformUnderscoreStringToTitle } from "../../utils/util";

const CasesTable = () => {
  const dispatch = useDispatch();
  const cases = useSelector((state) => state.cases.casesList);
  const authUser = useSelector((state) => state.auth);
  const departmentsState = useSelector((state) => state.departments);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterOption, setFilterOption] = useState("all");
  const itemsPerPage = 12;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const filteredItems = cases
    .filter((item) =>
      Object.values(item).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())
      )
    )
    .filter((item) => {
      switch (filterOption) {
        case "unassigned":
          return item.assignedTo.length === 0;
        case "my":
          return item.assignedTo.some(
            (assignee) => assignee.id === authUser.user.id
          );
        case "closed":
          return item.status === Constants.caseStatuses.CLOSED;
        default:
          return item.status !== Constants.caseStatuses.CLOSED;
      }
    })
    .sort((a, b) => {
      const valueA = a.caseNumber.toLowerCase();
      const valueB = b.caseNumber.toLowerCase();

      if (valueA < valueB) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (valueA > valueB) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

  const currentItems = filteredItems.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePrevPage = () => {
    setCurrentPage((currentPage) => currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((currentPage) => currentPage + 1);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const sortedItems = () => {
    if (sortColumn) {
      return currentItems.sort((a, b) => {
        const valueA = a[sortColumn].toLowerCase();
        const valueB = b[sortColumn].toLowerCase();

        if (valueA < valueB) {
          return sortOrder === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortOrder === "asc" ? 1 : -1;
        }
        return 0;
      });
    }

    return currentItems;
  };

  useEffect(() => {
    let unsubscribe = false;

    if (authUser !== null && authUser !== undefined) {
      const isSuperAdmin = authUser.user.userInfo.isSuperAdmin ? true : false;

      unsubscribe = subscribeToMyCases(dispatch, authUser.user.userInfo.mail);

      subscribeToDepartments(
        dispatch,
        isSuperAdmin,
        authUser.user.userInfo.department
      );
    }

    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [dispatch, authUser]);

  const handleSetActiveCase = (item) => {
    dispatch(setActiveCase(item));
    navigate("single_case/" + item.id);
  };

  const resolveNameOfCategory = (categoryId) => {
    const dep = departmentsState.departmentsList.find(
      (department) => department.id == categoryId
    );

    return dep ? dep.name : "No found";
  };

  const closedCasesCount = cases.filter(
    (item) => item.status === Constants.caseStatuses.CLOSED
  ).length;

  return (
    <>
      <div className="stats-container">
        <div className="stats-card shadow-sm">
          <h4>{cases.length}</h4>
          <span>Total Cases</span>
        </div>
        <div className="stats-card shadow-sm">
          <h4>{closedCasesCount}</h4>
          <span>Closed Cases</span>
        </div>
      </div>
      <div className="search-filter">
        <input
          type="search"
          name="search_case"
          id="search_case"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="filter">
          <button type="button" className="filter-more">
            <FilterListIcon />
          </button>
          <div className="viewing-opt">
            <span>Viewing:</span>
            <select
              name="filer_option"
              id="filer_option"
              value={filterOption}
              onChange={(e) => setFilterOption(e.target.value)}
            >
              <option value="all">All Cases</option>
              <option value="unassigned">Unassigned Cases</option>
              <option value="my">My Cases</option>
              <option value="closed">Closed Cases</option>
            </select>
          </div>
        </div>
      </div>
      <div className="table-container">
        <table className="cases-table">
          <thead>
            <tr style={{ backgroundColor: "#f2f2f2", padding: "0.5em" }}>
              <th onClick={() => handleSort("caseNumber")}>
                Case #
                {sortColumn === "caseNumber" &&
                  (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
              </th>
              <th onClick={() => handleSort("dateCreated")}>
                Date Created
                {sortColumn === "dateCreated" &&
                  (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
              </th>
              <th onClick={() => handleSort("caseSummary")}>
                Case summary
                {sortColumn === "caseSummary" &&
                  (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
              </th>
              <th onClick={() => handleSort("category")}>
                Category
                {sortColumn === "category" &&
                  (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
              </th>
              <th onClick={() => handleSort("caseSource")}>
                Channel
                {sortColumn === "caseSource" &&
                  (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
              </th>
              <th onClick={() => handleSort("reportedBy")}>
                Reported by
                {sortColumn === "reportedBy" &&
                  (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
              </th>
              <th onClick={() => handleSort("assignedTo")}>
                Assigned to
                {sortColumn === "assignedTo" &&
                  (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
              </th>
              <th onClick={() => handleSort("status")}>
                Status
                {sortColumn === "status" &&
                  (sortOrder === "asc" ? <>&uarr;</> : <>&darr;</>)}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedItems().map((item, index) => (
              <tr
                key={index}
                style={{ backgroundColor: "white", padding: "0.5em" }}
                onClick={() => handleSetActiveCase(item)}
              >
                <td>{item.caseNumber}</td>
                <td>
                  {moment
                    .utc(new Date(item.dateCreated), "MM/DD/YYYY h:mm A")
                    .add(2, "hours")
                    .format("MMM DD, YYYY HH:mm")}
                </td>
                <td>{item.caseSummary}</td>
                <td>
                  {item.category
                    ? resolveNameOfCategory(item.category)
                    : resolveNameOfCategory(item.department)}
                </td>
                <td>
                  {
                    // channels[Math.floor((Math.random() * channels.length))]
                    item.channel
                      ? transformUnderscoreStringToTitle(item.channel)
                      : "Portal"
                  }
                </td>
                <td>
                  <NameResolver email={item.caseRequester} />
                </td>
                <td>
                  {item.assignedTo.length > 0 ? (
                    <NamesOfAssignees assignees={item.assignedTo} />
                  ) : (
                    <>Unassigned</>
                  )}
                </td>
                <td>
                  {item.status != Constants.caseStatuses.CLOSED &&
                  item.assignedTo &&
                  item.assignedTo.length > 0
                    ? formatStatus("inprogress")
                    : formatStatus(item.status)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <button onClick={handleFirstPage} disabled={currentPage === 1}>
          <SkipPreviousIcon />
        </button>
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          <NavigateBeforeIcon />
        </button>
        <span>
          {currentPage} / {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          <NavigateNextIcon />
        </button>
        <button onClick={handleLastPage} disabled={currentPage === totalPages}>
          <SkipNextIcon />
        </button>
      </div>
    </>
  );
};

export default CasesTable;
