import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { subscribeToChatAsync } from "../slices/webChatSlice";
import Constants from "../constants/Constants";

const OpenChat = () => {
  const webChat = useSelector((state) => state.webChat);
  const dispatch = useDispatch();
  const { openChatId, openChatData } = webChat;

  const parseWhatsAppMessage = (message) => {
    if (message) {
      message = message.replace(/\n/g, "<br>");
      message = message.replace(/\*([^*]+)\*/g, "<b>$1</b>");
      message = message.replace(/_([^_]+)_/g, "<i>$1</i>");

      return message;
    }

    return null;
  };

  useEffect(() => {
    if (openChatId) subscribeToChatAsync(dispatch, openChatId);
  }, [openChatId, dispatch]);

  useEffect(() => {
    if (
      openChatData &&
      openChatData.status !== Constants.chatStages.CHAT_OPENED
    ) {
      // dispatch(setOpenChatId(null));
    }
  }, [openChatData]);

  return (
    <>
      <div>
        {webChat.openChatData && webChat.openChatData.messages
          ? webChat.openChatData.messages.map((message) => {
            if (
              message.messageType ===
              Constants.allowedIncomingMessages.TYPES.TEXT
            ) {
              return (
                <div
                  className={`chat-message-container ${message.sender === Constants.messageSender.CLIENT
                      ? "outgoing-message"
                      : ""
                    }`}
                >
                  <div
                    className="chat-message"
                    dangerouslySetInnerHTML={{
                      __html: parseWhatsAppMessage(message.messageContent),
                    }}
                  />
                </div>
              );
            } else {
            }
          })
          : ""}
      </div>
    </>
  );
};

export default OpenChat;
