import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAppendedComps,
  setOpenChatData,
  setShowMainOptions,
  setTypeOfChatOpen,
} from "../slices/webChatSlice";
import Category from "./Category";
import Announcements from "./Announcements";
import SelfHelp from "./SelfHelp";
import Contacts from "./Contacts";
import Constants from "../constants/Constants";

const mainChatOptions = [
  {
    id: "1",
    title: "Self-help",
    emoji: "🔍",
    description: "Quick access to helpful resources, forms, and FAQs.",
  },
  {
    id: "2",
    title: "Live Chat Support",
    emoji: "💬",
    description: "Get real-time help from a university representative.",
  },
  {
    id: "3",
    title: "Incident Management",
    emoji: "🛠️",
    description: "Report cases and manage incidents efficiently.",
  },
  {
    id: "4",
    title: "Announcement Board",
    emoji: "📢",
    description: "Stay updated with the latest university news and updates.",
  },
  {
    id: "5",
    title: "Provide Feedback",
    emoji: "✍️",
    description: "Share your thoughts and suggestions to help us improve.",
  },
  {
    id: "6",
    title: "Contacts & Directions",
    emoji: "📞📍",
    description:
      "Find updated phone numbers, emails, and directions for each campus.",
  },
];

const MainOptions = () => {
  const dispatch = useDispatch();
  const { showMainOptions, openChatId } = useSelector(
    (state) => state.webChat
  );

  const handleOptionClick = (event, option) => {
    event.stopPropagation();

    const newMessage = (
      <>
        <div className="chat-message-container outgoing-message">
          <div className="chat-message">
            {option.emoji} {option.title}
          </div>
        </div>
        {option.id === "1" && <SelfHelp />}
        {option.id === "2" && <Category />}
        {option.id === "4" || option.id === "5" || option.id === "3" ? (
          <div className="chat-message-container message">
            <div className="chat-message">
              {option.id === "5" && (
                <div>
                  <p>
                    🌟 We value your feedback! Please share any comments or
                    suggestions you have to help us improve in the message input
                    below. Your input is important to us!
                  </p>
                </div>
              )}
              {option.id === "3" && (
                <div>
                  <p>
                    To access the Incident Reporting and Management portal, log
                    in using your domain username and password.
                  </p>
                  <p>
                    Visit the
                    <a
                      href="https://helpdesk.wsu.ac.za"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Service Desk
                    </a>
                    to open, view, or update your cases. We're here to assist
                    you. Please don't hesitate to reach out if you via '
                    <b>Live Chat</b>' need further support! 😊
                  </p>
                  <p>
                    To return to the main menu, click '<b>home</b>' on top.
                  </p>
                </div>
              )}
              {option.id === "4" && <Announcements />}
            </div>
          </div>
        ) : (
          <></>
        )}
        {option.id === "6" && <Contacts />}
      </>
    );

    dispatch(setShowMainOptions(false));

    if (option.id === "5") {
      dispatch(setTypeOfChatOpen(Constants.openChatTypes.FEEDBACK));
      dispatch(setOpenChatData({ status: Constants.chatStages.CHAT_OPENED }));
    }

    const updatedComps = [newMessage];
    dispatch(setAppendedComps(updatedComps));
  };

  return (
    <>
      {!openChatId && showMainOptions ? (
        <div className="chat-message-container chose-option">
          <div className="options-message">
            {mainChatOptions.map((option) => (
              <button
                key={option.id}
                type="button"
                onClick={(event) => handleOptionClick(event, option)}
              >
                {option.emoji} {option.title}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default MainOptions;
