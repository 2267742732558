import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllCategoriesAsync,
  setAppendedComps,
  setOpenChatId,
  setTypeOfChatOpen,
} from "../slices/webChatSlice";
import { messages } from "../constants";
import Constants from "../constants/Constants";
import { saveNewMessage } from "../slices/api/webChatAPI";

const Category = () => {
  const { categories, appendedComps } = useSelector((state) => state.webChat);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [detailsFormShow, setDetailsFormShow] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getAllCategoriesAsync(dispatch);
  }, [dispatch]);

  const handleOnSelectCategory = (event, categoryLevel) => {
    const categoryId = event.target.value;

    if (categoryLevel === "0") {
      setSubCategories([]);
      const subCategories = categories.filter(
        (category) => categoryId === category.parentCategory
      );

      setSelectedMainCategory(categoryId);
      if (subCategories.length > 0) {
        const tempSubvategories = subCategories;
        tempSubvategories.push({
          id: messages.OtherId,
          name: messages.OtherLabel,
          parentCategory: categoryId,
        });
        setSubCategories(tempSubvategories);
        setDetailsFormShow(true);
      } else {
        setDetailsFormShow(true);
      }
    }

    if (categoryLevel === "1") {
      if (messages.OtherId === categoryId) {
        setSubCategoryId("main");
      } else {
        setSubCategoryId(categoryId);
      }
      setDetailsFormShow(true);
    }
  };

  const validateForm = () => {
    if (
      name === null ||
      name.length <= 0 ||
      email === null ||
      email.length <= 0
    ) {
      return {
        isValid: false,
        message: "Name and Email are required",
      };
    }

    if (subCategories.length > 0 && subCategoryId === null) {
      return {
        isValid: false,
        message:
          "Subcategory is required. Alternatively select Other/General if your case doesn't match any of the sub categories.",
      };
    }

    return { isValid: true };
  };

  const handledStartChat = async (event) => {
    event.preventDefault();
    const validation = validateForm();
    if (!validation.isValid) {
      alert("Error: " + validation.message);
    } else {
      setIsSubmitting(true);
      const categoryId = selectedMainCategory;
      const messageObject = {
        messages: [
          {
            messageType: Constants.allowedIncomingMessages.TYPES.TEXT,
            messageContent: Constants.messages.FIRST_MESSAGE,
            sender: Constants.messageSender.AGENT,
            dateCreated: new Date().toString(),
            isRead: false,
          },
        ],
        category: categoryId,
        subCategory: subCategoryId,
        dateCreated: new Date(),
        dateUpdated: new Date(),
        status: Constants.chatStages.CHAT_OPENED,
        channel: Constants.channels.WEB_CHAT,
        email: email,
        name: name,
      };

      try {
        const saveResults = await saveNewMessage(messageObject);
        setDetailsFormShow(false);
        setIsSubmitting(false);
        dispatch(setOpenChatId(saveResults.chatId));

        const newArr = [...appendedComps];
        newArr.splice(-1, 1);
        const updatedComps = [...newArr];
        dispatch(
          setTypeOfChatOpen(Constants.openChatTypes.SUPPORT_CONVERSATION)
        );
        dispatch(setAppendedComps(updatedComps));
      } catch (e) {
        setIsSubmitting(false);
        console.log("Error occured", e); //ToDo this needs to be better handled
      }
    }
  };

  return (
    <>
      <div className="chat-message-container message">
        <div className="chat-message">
          To connect with <b>live chat support</b>, please fill out the form below. Make sure to complete at least the 'Department' field to help us direct your inquiry to the right team.
        </div>
      </div>
      <div className="chat-message-container other-category">
        <div className="chat-message">
          <form>
            <select
              className="department"
              id="select-department"
              required
              value={selectedMainCategory}
              onChange={(event) => handleOnSelectCategory(event, "0")}
            >
              <option value="" disabled selected="true">
                ~ Select Department ~
              </option>
              {categories &&
                categories.map((category) => {
                  if (
                    category.parentCategory === messages.MainUpdated &&
                    category.chatEnabled === true
                  ) {
                    return (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    );
                  }
                  return null;
                })}
            </select>

            {subCategories.length > 0 && (
              <>
                <select
                  className="department"
                  id="select-department"
                  required
                  value={subCategoryId}
                  onChange={(event) => handleOnSelectCategory(event, "1")}
                >
                  <option value="" disabled selected>
                    ~ Select Category ~
                  </option>
                  {subCategories.map((category) => {
                    if (
                      category.parentCategory === selectedMainCategory &&
                      category.parentCategory !== messages.MainUpdated
                    ) {
                      return (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      );
                    }
                    return null;
                  })}
                </select>
              </>
            )}

            <div style={{ display: detailsFormShow ? "block" : "none" }}>
              <input
                type="text"
                className="userName"
                placeholder="Enter your name"
                aria-label="User Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="email"
                className="userEmail"
                placeholder="Enter your email"
                aria-label="User Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <button
                className="start-chat-btn main-btn"
                id="my-start-chat-with-agent-btn"
                onClick={handledStartChat}
                aria-label="Start Chat with Agent"
                disabled={isSubmitting}
              >
                {!isSubmitting ? "Chat with Agent" : "Opening chat..."}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Category;
